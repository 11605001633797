
.home-h2{
    text-align: center;
    font-size: 36px;
    font-weight: 400;
    line-height: 1.43;
    margin-bottom: 12px;
    min-height: 25px;

    @media (max-width: 1159px){
        font-size: 30px;
    }
    @media (max-width: 767px){
        font-size: 26px;
        margin-bottom: 6px;
    }
    &:lang(hy){
        font-weight: 600;
        font-size: 30px;

        @media (max-width: 1159px){
            font-size: 26px;
        }
        @media (max-width: 767px){
            font-size: 22px;
        }
    }
}
.home-static-container{

    > p{
        margin-bottom: 1.55rem;
        min-height: 20px;

        @media (max-width: 767px){
            margin-bottom: 1rem;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}
.home-header{
    min-height: 33.8vw;
    width: 100%;
    background-color: black;
    padding: 15px;

    > img{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        opacity: .6;
        object-fit: cover;
    }
    .home-header-text{
        padding: 15px;
        z-index: 1;

        h1{
            text-shadow: 0 3px 6px #000000;
            font-size: 42px;
            line-height: 1.4;
            margin-bottom: 20px;
            color: #ffffff;
            text-align: center;
            min-height: 25px;

            @media (max-width: 991px) {
                font-size: 26px;
            }
            @media (max-width: 575px) {
                font-size: 20px;
                margin-bottom: 10px;
                line-height: 1.3;
            }
        }
        > p{
            font-size: 18px;
            color: #ffffff;
            text-align: center;
            min-height: 20px;

            @media (max-width: 767px){
                font-size: 16px;
                line-height: 1.4;
            }
        }
    }
}
.home-about{
    padding: 48px 0 36px;

    @media (max-width: 767px){
        padding: 36px 0 24px;
    }
}
.home-about-container{
    padding: 12px 0;

    p{
        min-height: 20px;

        &:last-child{
            margin-bottom: 0;
        }
    }
}
.home-service{
    padding: 48px 0 36px;
    background-color: #F2F1F6;

    @media (max-width: 767px){
        padding: 36px 0 24px;
    }
}
.home-service-container{
    margin: 0 -12px;
    padding: 12px 0;
    justify-content: center;

    @media (max-width: 767px){
        padding: 0;
    }
}
.home-service-item{
    width: 33.33%;
    padding: 12px 12px 12px;
    text-align: center;

    @media (max-width: 767px) {
        width: 100%;
        padding: 24px 12px 24px;
    }
    > img{
        margin-bottom: 30px;

        @media (max-width: 1159px){
            margin-bottom: 12px;
        }
    }
    > h3{
        font-size: 1.2rem;
        margin-bottom: 16px;
        min-height: 20px;

        @media (max-width: 1159px){
            font-size: 1.1rem;
            margin-bottom: 12px;
        }
        &:lang(hy){
            font-weight: 600;
        }
    }
    > p{
        margin-bottom: 0;
        min-height: 20px;

        @media (max-width: 1159px){
            font-size: 14px;
        }
    }
}
.home-video-lesson{
    padding: 48px 0 48px;

    @media (max-width: 767px){
        padding: 36px 0 36px;
    }
}
.home-team{
    background-color: #F2F1F6;
    padding: 48px 0 36px;

    @media (max-width: 767px){
        padding: 36px 0 0;
    }
}
.home-team-container{
    margin: 0 -12px;
    padding: 12px 0;

    @media (max-width: 767px){
        margin: 0 -6px;
        padding: 24px 0;
    }
}
.home-team-item{
    padding: 12px;
    width: 25%;

    @media (max-width: 767px){
        padding: 0 6px;
        width: 50%;
        margin-bottom: 24px;
    }
    img{
        width: 177px;
        height: 177px;
        object-fit: cover;
        border-radius: 50%;
        margin-bottom: 1rem;

        @media (max-width: 1159px) {
            width: 140px;
            height: 140px;
        }
    }
    h3{
        font-size: 1.2rem;
        margin-bottom: 0.25rem;
        min-height: 20px;

        @media (max-width: 1159px){
            font-size: 1rem;
        }
        &:lang(hy){
            font-weight: 600;
        }
    }
    p{
        margin-bottom: 0;
        min-height: 20px;

        @media (max-width: 1159px){
            font-size: 13px;
        }
    }
}
.home-student {
    padding: 48px 0 36px;

    @media (max-width: 767px){
        padding: 36px 0 12px;
    }
    .home-student-info-slider {
        margin: 0 -12px;
        padding: 12px 0;

        @media (max-width: 767px){
            flex-direction: column;
            margin: 0;
            padding: 24px 0 0;
        }
        .description {
            padding: 12px;
            width: 33.33%;

            @media (max-width: 767px){
                margin-bottom: 36px;
                width: 100%;
                padding: 0;
            }
            img {
                display: block;
                margin: 0 auto 15px;
                height: 80px;
                width: 80px;
                object-fit: cover;
                border-radius: 50%;
                transition: all .3s;

                @media (max-width: 767px){
                    width: 100px;
                    height: 100px;
                }
            }
            p{
                line-height: 1.35;
                font-size: 16px;
                margin-bottom: 0;
                min-height: 20px;

                @media (max-width: 1159px){
                    font-size: 14px;
                }
            }
            h3{
                font-size: 1.2rem;
                margin-bottom: 1em;
                font-weight: 600;
                color: black;
                min-height: 25px;
            }
        }
    }
}
.our-daily{
    padding: 48px 0 48px;
    background-color: #F2F1F6;

    @media (max-width: 767px){
        padding: 36px 0 36px;
    }
}
.our-daily-container{
    width: 100%;
    height: 762px;
    position: relative;
    margin: 36px auto;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1159px){
        height: 508px;
    }
    @media (max-width: 767px){
        height: 65vw;
        margin: 24px auto;
    }
    img{
        position: absolute;
        object-fit: cover;

        &:nth-of-type(1){
            width: 66%;
            left: 0;
            top: 0;
            height: 66%;
        }
        &:nth-of-type(2){
            width: 32%;
            right: 0;
            top: 0;
            height: 31%;
        }
        &:nth-of-type(3){
            width: 32%;
            left: 0;
            bottom: 0;
            height: 31%;
        }
        &:nth-of-type(4){
            width: 32%;
            left: 34%;
            bottom: 0;
            height: 31%;
        }
        &:nth-of-type(5){
            width: 32%;
            right: 0;
            bottom: 0;
            height: 66%;
        }
    }
}
